import React from 'react';
import { PiPlusThin, PiMagicWandThin, PiCreditCardThin, PiAddressBookThin} from 'react-icons/pi';
import './css/footer.css'; // Import the CSS if you have footer-specific styles
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const FooterBar = () => {
  const navigate = useNavigate();
  return (
    <div className="footer-container">
      <Link className="footer-button" to="/home-page">
        <PiMagicWandThin size={30} strokeWidth={3} />
      </Link>
      <Link className="footer-button" to="/create">
        <PiPlusThin size={30} strokeWidth={3} />
      </Link>
      <Link className="footer-button" to="/events">
        <PiCreditCardThin size={30} strokeWidth={3} />
      </Link>
      <Link className="footer-button" to="/contacts">
        <PiAddressBookThin size={30} strokeWidth={3} />
      </Link>
    </div>
  );
}

export default FooterBar;
