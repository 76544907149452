import React, { useEffect, useContext, useState } from 'react';
import PhoneOTPLogin from "./PhoneOTPLogin";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./HomePage";
import CreateEvent from "./CreateEvent";
import SimpleForm from "./SimpleForm";
import { AuthContext } from "../context/AuthContext";
import Testing from "./Testing";
import EventPage from "./EventPage";
import FirstPage from "./FirstPage";
import FooterWrapper from "./FooterWrapper";
import { useUserContext } from '../context/UserContext';
import JeevesRequestUtils from '../context/JeevesRequestUtils';
import debug from '../debug_tools/debugLogger';
import { EventDataProvider } from '../context/EventDataContext';
import ExpiredEvent from './ExpiredEvent';
import ContactPage from './ContactPage';
import './css/loadingpage.css';
import LoadingScreen from './utils/LoadingScreen';
import ProtectedRoute from './ProtectedRoute';

const App = () => {
  const { authState, authenticated, loading } = useContext(AuthContext);
  const { userData } = useUserContext();
  const { fetchData } = JeevesRequestUtils();
  const [timestamp, setTimestamp] = useState(Date.now()); // For tracking refreshes

  useEffect(() => {
    if (!authenticated()) {
      return;
    }
    debug.log('refreshing data');
    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
      setTimestamp(Date.now());
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    document.body.addEventListener('touchmove', (e) => {
      const target = e.target;
      if (target.closest('.scroll') === null) {
        e.preventDefault();
      }
    });
  }, []);

  if (!!loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="scroll">
      <body>
        <EventDataProvider>
          <Router>
            <Routes>
              <Route path="/login" element={<PhoneOTPLogin />} />
              <Route path="/simple" element={<SimpleForm />} />

              <Route path="/" element={<ProtectedRoute authenticated={authenticated()} element={<FooterWrapper><FirstPage /></FooterWrapper>} />} />
              <Route path="/create" element={<ProtectedRoute authenticated={authenticated()} element={<FooterWrapper><CreateEvent /></FooterWrapper>} />} />
              <Route path="/events" element={<ProtectedRoute authenticated={authenticated()} element={<FooterWrapper><HomePage /></FooterWrapper>} />} />
              <Route path="/expired" element={<ProtectedRoute authenticated={authenticated()} element={<FooterWrapper><ExpiredEvent /></FooterWrapper>} />} />
              <Route path="/contacts" element={<ProtectedRoute authenticated={authenticated()} element={<FooterWrapper><ContactPage /></FooterWrapper>} />} />
              <Route path="/test" element={<ProtectedRoute authenticated={authenticated()} element={<Testing />} />} />
              <Route path="/r/:eventId" element={<ProtectedRoute authenticated={authenticated()} element={<FooterWrapper><EventPage /></FooterWrapper>} />} />

              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Router>
        </EventDataProvider>
      </body>
    </div>
  );
};

export default App;
