import { Form, Card } from "react-bootstrap";
import OtpInput from "otp-input-react";
import { useEffect, useState, useContext } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "./css/Login.css";
import { useUserContext } from "../context/UserContext";
import { AuthContext } from "../context/AuthContext";
import { AxiosContext } from "../context/AxiosContext";
import { BsArrowReturnRight } from 'react-icons/bs';
import debug from '../debug_tools/debugLogger';
import backgroundImage from '../assets/images/bg3.png';
import { useLocation } from "react-router-dom";

export default function PhoneOTPLogin() {
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sectionStyle, setSectionStyle] = useState({});
  const navigate = useNavigate();
  const { setUserData } = useUserContext();
  const { authState, setSessionInLocalStorage, logout } = useContext(AuthContext);
  const { authAxios } = useContext(AxiosContext);
  const location = useLocation();

  function formatPhoneNumber(phone) {
    return phone.startsWith('+') ? phone : '+' + phone;
  }

  function onSignUp(e) {
    e.preventDefault();
    if (!phone) { // Check if phone number is valid
      toast.error("Please enter a valid phone number.");
      return;
    }

    const formattedPhone = formatPhoneNumber(phone);
    setLoading(true); // Start loading

    authAxios.post('/user_auth/request_otp/', { phone_number: formattedPhone })
      .then(response => {
        setShowOTP(true);
      })
      .catch(error => {
        debug.log('Failed to send OTP:', error.message);
        toast.error("An error occurred. Please retry");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleOtpSubmit(e) {
    e.preventDefault();
    setLoading(true); // Start loading
    const formattedPhone = formatPhoneNumber(phone);

    authAxios.post('/user_auth/get_otp_based_auth_token/', { phone_number: formattedPhone, country_code: "+1", otp: otp })
      .then(response => {
        debug.log('response:' + JSON.stringify(response))
        const sessionData = {
          phone_number: phone,
          refresh: response.data.refresh,
          access: response.data.access,
          newly_created: response.data.newly_created_user
        };
        setSessionInLocalStorage(sessionData);
        const params = new URLSearchParams(location.search);
        const redirect = params.get('redirect');
        navigate(redirect || '/');
      })
      .catch(error => {
        debug.groupCollapsed('Request Error');
        if (error.response) {
          toast.error("Invalid OTP. Please try again.");
          debug.log('Failed request:', error.response.data.message);
          debug.log('Status:', error.response.status);
          debug.log('Headers:', error.response.headers);
        } else if (error.request) {
          debug.log('No response received:', error.request);
        } else {
          debug.log('Setup Error:', error.message);
        }
        debug.groupEnd(); // Ends the 'Request Error' group

      })
      .finally(() => {
        setLoading(false);
      });
  }

  const navigateToCreateEvent = () => {
    navigate('/create');
  };

  useEffect(() => {
    const sectionStyle = {
      background: `linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2)), url(${backgroundImage})`,
      backgroundSize: 'cover', // This ensures the image covers the entire section
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat'
    };
    setSectionStyle(sectionStyle);
  }, []);


  useEffect(() => {
    if (authState.access) {
      setPhone(authState.phone_number);
    }
  }, [authState, setUserData]);

  useEffect(() => {
    if (loading) {
      toast.loading("Verifying...")
    }
  }, [loading]);

  return (
    <>
      <section style={{ ...sectionStyle, width: '100vw' }}>

        <div className="otp-login-container">
          <div className="login-card-container">
            <script
              src="https://www.google.com/recaptcha/api.js?onload=recaptchaCallback&render=explicit"
              async
              defer
            ></script>
            <div>
              <Toaster toastOptions={{ duration: 2000 }} />
              <div className="form-card">
                {showOTP ? (
                  <>
                    <Card>
                      <Card.Body>
                        <h2 className="text-center mb-4">Enter OTP Code</h2>
                        <Form onSubmit={handleOtpSubmit}>
                          <OtpInput
                            value={otp}
                            onChange={setOtp}
                            OTPLength={6}
                            otpType="number"
                            disabled={false}
                            autoFocus
                            className="otp-container"
                          ></OtpInput>
                          <button
                            id="verify-otp"
                            className="custom-button"
                            disabled={loading}
                            type="submit"
                          >
                            Submit OTP
                          </button>
                        </Form>
                      </Card.Body>
                    </Card>
                  </>
                ) : (
                  <>
                    <Card>
                      <Card.Body>
                        <h3 className="mb-4">Login or Sign-up</h3>
                        <Form onSubmit={onSignUp}>
                          <div className="phone-input-container">
                            <PhoneInput
                              className="phone-input"
                              country={"us"}
                              value={phone}
                              onChange={setPhone}
                              countryCodeEditable={false}
                              international={true}
                            />
                          </div>
                          <button
                            type="submit"
                            className="custom-button"
                            disabled={loading}
                          >
                            {loading ? 'Sending...' : <BsArrowReturnRight />}
                          </button>
                        </Form>
                      </Card.Body>
                      <div style={{ width: '90%', borderTop: '1px solid #000', margin: '0 1.5rem' }} />
                      <div className="login-create-event">
                        <Card.Body>
                          <button className="custom-button create-event-btn" onClick={navigateToCreateEvent}>Create an Event</button>
                        </Card.Body>
                      </div>
                    </Card>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}