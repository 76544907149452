// UserContext.js
import { createContext, useContext, useEffect, useState } from 'react';
import { AxiosContext } from './AxiosContext';
import { AuthContext } from "../context/AuthContext";
import debug from '../debug_tools/debugLogger'

const UserContext = createContext();

export function useUserContext() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const [userData, setUserData] = useState({
    id: null,              // User ID
    primary_phone_number_e164: null,    // Phone number of the user
    country_code: null,    // Country code related to the phone number
    first_name: null,      // First name of the user
    last_name: null,       // Last name of the user
    email: null,           // Email address of the user
    city: null,            // City of the user
    is_verified: false,    // Verification status
    date_of_birth: null,   // Date of birth of the user
    gender: null,          // Gender of the user
    preferredCommunication: 'phone',  // Preferred mode of communication
  });

  const { authAxios, tokenRefreshed } = useContext(AxiosContext);
  const { authState } = useContext(AuthContext);

  const updateUserData = (newUserData) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      ...newUserData,
    }));
  }

  async function fetchUserProfile() {
    const usernum = `+${authState.phone_number}`;
    const response = await authAxios.post('/profile/get_user_profile/', { phone_number: usernum })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          updateUserData(response.data.data);
        } else {
          throw new Error(response);
        }
      })
      .catch((error) => {
        debug.error('error fetching user profile:' + error);
      });
  }

  useEffect(() => {
    if (tokenRefreshed) {
      debug.log('fetching user profile')
      fetchUserProfile();
    }
  }, [tokenRefreshed]);

  return (
    <UserContext.Provider value={{ userData, updateUserData, fetchUserProfile }}>
      {children}
    </UserContext.Provider>
  );
}
