import TestEventCard from "./TestEventCard";

const Testing = () => {
  const events = {
    created_events: [
        {
            id: 1,
            title: "Event 1",
            description: "This is the description for event 1."
        },
        {
            id: 2,
            title: "Event 2",
            description: "This is the description for event 2."
        },
        {
            id: 3,
            title: "Event 3",
            description: "This is the description for event 3."
        },
        {
            id: 4,
            title: "Event 4",
            description: "This is the description for event 4."
        },
    ]
};
  return (
    <div className="event-big-screen">
    <TestEventCard />
    </div>
  );
}

export default Testing;