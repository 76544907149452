import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PiTimerThin, PiMapPinLineThin } from 'react-icons/pi';

const NewEventCard = ({ cardData, color = 'cool' }) => {
  const history = useNavigate(); // Initialize useHistory hook

  // Convert the UTC date string to a more readable format
  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Function to handle card click
  const handleCardClick = () => {
    console.log('Clicked on card' + JSON.stringify(cardData))
    history(`/r/${cardData.id}`); // Navigate to /r/:event_id
  };

  return (
    <div>
      {/* Main Event Card */}
      <div
        className={`invitationCard color-${color}`}
        onClick={handleCardClick} // Call handleCardClick function when card is clicked
      >
        <h2>{cardData.title}</h2>
        <div className="div-line" />
        <p>
          <strong><PiTimerThin /> </strong>
          {formatDate(cardData.start_time)}
        </p>
        {
          cardData.venue_formatted_address &&
          <p><strong><PiMapPinLineThin /> </strong>{cardData.venue_formatted_address}</p>
        }
  
      </div>
    </div>
  );
};

export default NewEventCard;
