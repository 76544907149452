import React, { useState, useEffect, useRef } from 'react';
import styles from './editable.module.css'; // Make sure to include your CSS module here

const InlineEditable = ({ value, setValue, multiline = false, rows = 1, editable = true, placeholder = 'Enter value' }) => {
  const [editingValue, setEditingValue] = useState(value);
  const textareaRef = useRef();

  const onChange = (event) => setEditingValue(event.target.value);

  const onKeyDown = (event) => {
    if (event.key === "Escape" || (event.key === "Enter" && !event.shiftKey)) {
      event.preventDefault(); // Prevent default "Enter" behavior
      event.target.blur();
    }
  };

  const onBlur = (event) => {
    if (event.target.value.trim() === "") {
      setEditingValue(value);
    } else {
      setValue(event.target.value);
    }
  };

  const onInput = (target) => {
    if (target.scrollHeight > 33) {
      target.style.height = "5px";
      target.style.height = target.scrollHeight - 16 + "px";
    }
  };

  useEffect(() => {
    if (multiline && textareaRef.current) {
      onInput(textareaRef.current);
    }
  }, [multiline]);

  useEffect(() => {
    setEditingValue(value);
  }, [value]);

  return (
    <>
      {editable ? (
        multiline ? (
          <textarea
            className={styles['inline-edit-multiinput']}
            rows={rows}
            aria-label="Field name"
            value={editingValue}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onInput={(event) => onInput(event.target)}
            ref={textareaRef}
            placeholder={placeholder} // Add this line
          />
        ) : (
          <input
            className={styles['inline-edit-input']}
            type="text"
            aria-label="Field name"
            value={editingValue}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            placeholder={placeholder} // Add this line
          />
        )
      ) : (
        <span>
          {multiline ? (value || "").split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          )) : value || ""}
        </span>
      )}
    </>
  );
};

export default InlineEditable;
