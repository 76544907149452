import React, { useState, useContext, useEffect } from "react";
import { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import MovingComponent from 'react-moving-text';
import { Link } from 'react-router-dom';

import './css/navbar.css'
import ProfilePopup from "./ProfilePopup";
import { useUserContext } from "../context/UserContext";
import { AuthContext } from "../context/AuthContext";
import CreateEventPopup from "./CreateEventPopup";
import AvatarMenu from "./AvatarMenu";

import JeevesRequestUtils from "../context/JeevesRequestUtils";

const Navbar = ({ callback }) => {
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [showCreateEventModal, setShowCreateEventModal] = useState(false);
    const { userData } = useUserContext();
    const navigate = useNavigate();
    const { logout } = useContext(AuthContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const { fetchUserProfile } = JeevesRequestUtils();

    const toggleProfilePopup = () => {
        setShowProfileModal((prev) => !prev);
    };

    const toggleCreateEventPopup = () => {
        setShowCreateEventModal((prev) => !prev);
    }

    const onCreateEvent = () => {
        navigate('/create');
    }

    const onLogout = () => {
        logout();
    }

    const createEventCallbackTrigger = () => {
        toggleCreateEventPopup();
        setMenuOpen(false);
        callback();
    }

    const profileUpdateCallbackTrigger = () => {
        toggleProfilePopup();
        setMenuOpen(false);
    };

    return (
        <div className="navbar">
            <Toaster toastOptions={{ duration: 2000 }} />
            { /* left side of navbar */}
            <div className='navbar-left' >
                <Link to='/'>
                    <MovingComponent
                        duration="1000ms"
                        delay="5s"
                        direction="normal"
                        timing="linear"
                        iteration="infinite"
                        fillMode="none">
                        Jeeves
                    </MovingComponent>
                </Link>
            </div>
            <ProfilePopup user={userData} isOpen={showProfileModal} onClose={profileUpdateCallbackTrigger} />
            <CreateEventPopup user={userData} isOpen={showCreateEventModal} onClose={createEventCallbackTrigger} />
            { /* right side of navbar */}
            <div className="navbar-right">
                <AvatarMenu
                    toggleCreateEventPopup={toggleCreateEventPopup}
                    toggleProfilePopup={toggleProfilePopup}
                    onLogout={onLogout}
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                />
            </div>
        </div>
    )
}

export default Navbar;