import React, { useState, useEffect } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import Modal from 'react-modal';
import './shareLinkModal.css';
import { BsFacebook, BsTwitter, BsWhatsapp } from 'react-icons/bs';
import { FiX } from 'react-icons/fi';

const CopyLink = ({ url }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(url).then(() => {
      setIsCopied(true);

      // Reset the button text after some time
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    });
  };

  return (
    <div className='copy-link-container'>
      <div className='copy-link-url'>
        {url}
      </div>
      <button className='copy-link-button' onClick={handleCopyClick}>
        {isCopied ? 'Copied!' : 'Copy'}
      </button>
    </div>
  );
};

const ShareLinkModal = ({ isOpen, closeModal, url, title }) => {
  return (
    <div className='share-link-modal-dd'>
      <Modal className='share-link-modal' overlayClassName="share-link-modal-overlay" isOpen={isOpen} onRequestClose={closeModal} ariaHideApp={false}>
        <div className='share-link-modal-container'>
          <div className='share-link-modal-header'>
            Share
            <div className='share-link-modal-header-closer'>
              <button onClick={closeModal}><FiX /></button>
            </div>
          </div>
          <CopyLink url={url} />          
          <div className='share-link-modal-social-buttons'>
            <FacebookShareButton url={url} quote={title} className='share-social-button'>
              <BsFacebook size={60} />
              <div className='share-social-button-text'>
                <small>facebook</small>
              </div>
            </FacebookShareButton>

            <TwitterShareButton url={url} title={title} className='share-social-button'>
              <BsTwitter size={60} />
              <div className='share-social-button-text'>
                <small>twitter</small>
              </div>
            </TwitterShareButton>

            <WhatsappShareButton url={url} title={title} className='share-social-button'>
              <BsWhatsapp size={60} />
              <div className='share-social-button-text'>
                <small>whatsapp</small>
              </div>
            </WhatsappShareButton>
          </div>

        </div>
      </Modal>
    </div>
  );
};

export default ShareLinkModal;
