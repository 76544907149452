import React, { useState, useCallback, useContext } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './css/SimpleForm.css'; // Custom styles for the form
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import UserPhoneRow from './UserPhoneRow';
import EventPreviewModal from './EventPreviewModal';
import { useUserContext } from '../context/UserContext';
import { AxiosContext } from '../context/AxiosContext';
import Avatar from 'react-avatar';
import { AiOutlineClose } from 'react-icons/ai';
import PlacesAutoCompleteComp from './PlacesAutoCompleteComp';
import { toast } from "react-hot-toast";
import { useEventDataContext } from '../context/EventDataContext';
import JeevesRequestUtils from '../context/JeevesRequestUtils';

const initialState = {
  location: '',
  start_time: '',
  end_time: '',
  description: '',
  loading: false,
  submitting: false,
  showPreview: false,
  eventDataForPreview: null,
  showConfirmation: false,
  participants: [],
  cohosts: [],
  gettingThereInstructions: '',
  additionalInstructions: '',
  durationValue: '',
  durationUnit: 'hours',
  title: '',
  isPublic: false,
};

const SimpleForm = ({ cb, editMode = false, existingEventData = null }) => {
  const [state, setState] = useState(editMode ? existingEventData : initialState);
  const { userData } = useUserContext();
  const history = useNavigate();
  const { authAxios } = useContext(AxiosContext);
  const [showEndTime, setShowEndTime] = useState(false);
  const [ispublic, setIsPublic] = useState(false);
  const setFormField = useCallback(
    (name, value) => setState((prev) => ({ ...prev, [name]: value })),
    []
  );
  const { refreshEventData } = useEventDataContext();
  const { handleEventPublish } = JeevesRequestUtils();

  const handleEventPrivacyChange = (selectedOption) => {
    setIsPublic(!ispublic);
    setFormField('isPublic', !ispublic);
  };

  const handleLocationSelect = (place) => {
    if (!place) {
      setFormField('location', '');  // reset location if the place is not provided
      return;
    }

    // Extract the venue name (i.e., the "premise")
    const venueComponent = place.address_components.find(comp => comp.types.includes('premise'));
    const venueName = venueComponent ? venueComponent.long_name : '';

    const formattedAddress = place.formatted_address;

    setFormField('location', {
      venueName: venueName,
      formattedAddress: formattedAddress
    });
  };

  const handleTitleChange = (e) => {
    setFormField('title', e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setFormField('description', e.target.value);
  };

  const handleDateTimeChange = (key, value) => {
    setState(prevState => ({ ...prevState, [key]: value }));
  };

  const handleGettingThereInstructionsChange = (e) => {
    setFormField('gettingThereInstructions', e.target.value);
  };

  const handleAdditionalInstructionsChange = (e) => {
    setFormField('additionalInstructions', e.target.value);
  };

  const addParticipants = useCallback(
    () => setState((prev) => ({
      ...prev, participants: [...prev.participants, {
        id: Date.now(),
        first_name: ' ',
        last_name: ' ',
        country_code: '+1',
        phone_number: ' ',
        participant_type: 'invitee'
      }]
    })),
    []
  );

  const addCoHost = useCallback(
    () => setState((prev) => ({ ...prev, cohosts: [...prev.cohosts, { id: Date.now(), name: '', phoneNumber: '' }] })),
    []
  );

  const updateParticipants = useCallback(
    (id, updatedParticipants) => setState((prev) => ({
      ...prev,
      participants: prev.participants.map((row) => (row.id === id ? updatedParticipants : row)),
    })),
    []
  );

  const removeParticipants = useCallback(
    (id) => setState((prev) => ({
      ...prev,
      participants: prev.participants.filter((row) => row.id !== id),
    })),
    []
  );

  const updateCohost = useCallback(
    (id, updateCohost) => setState((prev) => ({
      ...prev,
      cohosts: prev.cohosts.map((row) => (row.id === id ? updateCohost : row)),
    })),
    []
  );

  const removeCohost = useCallback(
    (id) => setState((prev) => ({
      ...prev,
      cohosts: prev.cohosts.filter((row) => row.id !== id),
    })),
    []
  );

  const submitDraftEvent = async (confirmed) => {
    if (!confirmed) {
      return;
    }

    if (!isValidForm()) {
      document.getElementById('simple-form').classList.add('was-validated');
      return;
    }

    const eventData = getEventData();
    console.log('current event: ' + JSON.stringify(eventData))
    await postDraftEvent(eventData);
  };

  const postDraftEvent = async (eventData) => {
    try {
      const response = await authAxios.post('/events/', {
        event_data: eventData,
        participants: eventData.participants,
      });

      console.log('Event draft successfully:', JSON.stringify(response));

      // Returning the response data
      return response.data;

    } catch (error) {
      console.error('Error creating event:', error.message);
      throw new Error(error);
    }
  };

  const publishEvent = async () => {
    if (!isValidForm()) {
      toast.error('Form is not valid');
      document.getElementById('simple-form').classList.add('was-validated');
      return;
    }

    try {
      const data = getEventData();
      const resp = await postDraftEvent(data);

      console.log(`now publishing event: ${JSON.stringify(resp)}`);

      const eventPublishResponse = await handleEventPublish(resp.event_id);

      console.log('Event created successfully:', eventPublishResponse);

      refreshEventData();
      history('/events');
    } catch (error) {
      console.error('Error:', error.message);
    }

    // If you need to do something after the event is created successfully, you can add it here
  };


  const previewEvent = (event) => {
    event.preventDefault();

    setFormField('loading', true);

    const eventData = getEventData();
    console.log('current event: ' + JSON.stringify(eventData))

    setFormField('eventDataForPreview', eventData);
    setFormField('showPreview', true);
  };

  const draftEvent = async () => {
    setFormField('loading', true);
    setFormField('submitting', true);
    submitDraftEvent({ confirmed: true })
      .then(() => {
        refreshEventData();
        history('/events');
        setFormField('submitting', false);
      });

  };

  const cancelPreview = () => {
    setFormField('showPreview', false);
    setFormField('loading', false);
  };

  function formatToCorrectDate(dateObj, offsetHours = 0) {
    const z = (n) => ('0' + n).slice(-2); // Helper function to prepend zero

    let year = dateObj.getUTCFullYear();
    let month = z(dateObj.getUTCMonth() + 1);
    let day = z(dateObj.getUTCDate());
    let hours = z(dateObj.getUTCHours() + offsetHours);
    let minutes = z(dateObj.getUTCMinutes());
    let seconds = z(dateObj.getUTCSeconds());

    const offsetSign = offsetHours >= 0 ? '+' : '-';
    const offset = offsetSign + z(Math.abs(offsetHours)) + ':00';

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}${offset}`;
  }

  const isValidForm = () => {
    const { location, title, start_time, description } = state;
    return location && title && start_time && description;
  };

  const getEventData = () => {
    const { title, participants, location, description, start_time, end_time } = state;
    console.log('participant:', JSON.stringify(userData))
    { /* TODO: time and date aren't right */ }
    return {
      host_id: userData.username,
      venue_str: location.venueName,
      venue_formatted_address: location.formattedAddress,
      participants: participants,
      description: description,
      start_time: formatToCorrectDate(state.start_time),
      event_state: "draft",
      event_visibility: "invite_only",
      title: title,
      getting_there_instructions: state.gettingThereInstructions || "",
      additional_instructions: state.additionalInstructions || "",
      end_time: end_time ? formatToCorrectDate(end_time) : formatToCorrectDate(start_time)
    };
  };

  const previewAdaptor = (firstJson) => {
    const secondJson = {
      created_by: {
        username: userData.id,
        primary_phone_number_e164: userData.primary_phone_number_e164,
        first_name: userData.first_name,
        last_name: userData.last_name,
      },
      image: null,
      title: firstJson.title,
      description: firstJson.description,
      start_time: firstJson.start_time,
      end_time: firstJson.end_time,
      event_state: firstJson.event_state,
      venue_str: firstJson.venue_str,
      co_hosts: [],
      invites: [],
      venue_formatted_address: firstJson.venue_formatted_address,
      getting_there_instructions: firstJson.getting_there_instructions,
      additional_instructions: firstJson.additional_instructions
    };

    const convertParticipantToInvite = (participant) => {
      return {
        id: '', // Generate or assign some ID
        invitee: {
          id: participant.id.toString(),
          username: participant.phone_number,
          primary_phone_number_e164: participant.phone_number,
          first_name: participant.first_name.trim(),
          last_name: participant.last_name.trim(),
          email: ''
        },
        event_id_str: secondJson.id,
        invitee_id_str: participant.id.toString(),
        is_deleted: false,
        rsvp_state: 'NO',
        event: secondJson.id
      };
    };

    if (firstJson.participants && firstJson.participants.length > 0) {
      secondJson.invites = firstJson.participants.map(convertParticipantToInvite);
    }

    if (firstJson.cohosts && firstJson.cohosts.length > 0) {
      secondJson.co_hosts = firstJson.cohosts.map(convertParticipantToInvite);
    }

    return secondJson;
  };

  return (
    <div className='form-container'>
      <Form id='simple-form' onSubmit={previewEvent}>
        <h1>Create Your Event</h1>
        <div className='separation-line'></div>

        <Form.Group className='mb-3 event-title'>
          <Form.Control
            value={state.title}
            onChange={handleTitleChange}
            rows={4}
            required
            placeholder='Event title'
            className='form-control'
          />
          <Form.Control.Feedback type='invalid'>Title is required.</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className='mb-3'>
          <div className='date-time-container'>
            <DatePicker
              selected={state.start_time}
              onChange={(date) => handleDateTimeChange('start_time', date)}
              dateFormat='MMMM d, yyyy h:mm aa'
              placeholderText='📅 Select Start Time'
              required
              className='form-control'
              wrapperClassName="datePicker"
              minDate={new Date()}
              showTimeSelect
            />
          </div>
        </Form.Group>
        <div className="end-time-container">
          {state.start_time && showEndTime ? (
            <Form.Group className='mb-3'>
              <div className='date-time-container'>
                <DatePicker
                  selected={state.end_time}
                  onChange={(date) => handleDateTimeChange('end_time', date)}
                  dateFormat='MMMM d, yyyy h:mm aa'
                  placeholderText='📅 Select End Time'
                  required
                  className='form-control'
                  wrapperClassName="datePicker"
                  minDate={state.start_time}
                  showTimeSelect
                />
                <span id="close-end-time-text" onClick={() => setShowEndTime(false)}> <AiOutlineClose /></span>
              </div>
            </Form.Group>) : (
            <span id="show-end-time-text" onClick={() => setShowEndTime(true)}>+ Add event end time</span>

          )}
        </div>
        <div className='event-location'>
          <PlacesAutoCompleteComp setAddress={handleLocationSelect} />
        </div>
        <div className='privacy-toggle'>
          <input
            type="checkbox"
            className="btn-check"
            id="btn-check-outlined"
            autoComplete="off"
            value={!ispublic}
            onChange={handleEventPrivacyChange}
          />
          <label className="btn btn-outline-primary" htmlFor="btn-check-outlined">
            {state.isPublic ? 'This is a Public Event' : 'This is a Private Event'}
          </label>
        </div>
        <Form.Group className='mb-3'>
          <Form.Control
            as='textarea'
            value={state.description}
            onChange={handleDescriptionChange}
            rows={4}
            required
            placeholder='Description'
            className='form-control'
          />
          <Form.Control.Feedback type='invalid'>Description is required.</Form.Control.Feedback>
        </Form.Group>

        <div>
          <span id="add-cohost-text" onClick={() => addCoHost()}> + Add Co-host</span>
        </div>
        {/* Button to add a new row */}
        <div className="cohost-container">
          {state.cohosts.map((row) => (
            <UserPhoneRow
              key={row.id}
              row={row}
              onChange={updateCohost}
              onRemove={removeCohost}
            />
          ))}
        </div>


        <Form.Group className='mb-3'>
          <Form.Control
            type="text"
            value={state.gettingThereInstructions}
            onChange={handleGettingThereInstructionsChange}
            placeholder="Instructions to Get There"
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Control
            type="text"
            value={state.additionalInstructions}
            onChange={handleAdditionalInstructionsChange}
            placeholder="Additional Instructions"
          />
        </Form.Group>
        {/* Render the rows */}
        <div className="rows-container">
          {state.participants.map((row) => (
            <UserPhoneRow
              key={row.id}
              row={row}
              onChange={updateParticipants}
              onRemove={removeParticipants}
            />
          ))}
        </div>
        <div className="button-container">
          {/* Button to add a new row */}
          <Button variant="success" onClick={addParticipants}>
            Add Member
          </Button>
          <Button type='submit' variant='primary' value='submit'>
            Preview
          </Button>
        </div>
      </Form>
      {/* Modal for showing the preview */}
      {state.eventDataForPreview && (<EventPreviewModal
        show={state.showPreview && state.loading}
        onHide={() => setFormField('showPreview', false)}
        eventData={previewAdaptor(state.eventDataForPreview)}
        loading={state.loading}
        handleCancel={cancelPreview}
        handleConfirm={publishEvent}
        submitting={state.submitting}
        handleSaveDraft={draftEvent}
      />)}
    </div>
  );
};

export default SimpleForm;
