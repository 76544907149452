import SimpleForm from './SimpleForm';
import './css/createevent.css';

const CreateEvent = () => {
  return (
    <div className="create-event-container">
        <SimpleForm />
    </div>
  );
};

export default CreateEvent;
