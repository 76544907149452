import React, { useContext, useState } from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import { IoIosColorWand } from 'react-icons/io';
// import axios from 'axios';
import { useUserContext } from '../context/UserContext';
import { AxiosContext } from '../context/AxiosContext';
import debug from '../debug_tools/debugLogger';
import './css/generativePrompt.css'

const FirstPage = () => {
  const { authAxios } = useContext(AxiosContext);
  const [firstInput, setFirstInput] = useState('');
  const [secondInput, setSecondInput] = useState('');
  const [payload, setPayload] = useState(null);
  const [step, setStep] = useState(1);

  async function handleSecondSubmit() {
    try {
      debug.log("calling create_prompt_based_event", firstInput, secondInput)
      let request_data = {
        event_prompt: firstInput,
        start_time: secondInput
      }
      const response = await authAxios.post('/events/create_prompt_based_event/', request_data);
      setPayload(response.data);
      setStep(3);
    } catch (e) {
      debug.log('error in create_prompt_based_event: ', JSON.stringify(e));
      throw e;
    }
  }

  function handleReset() {
    setFirstInput('');
    setSecondInput('');
    setPayload(null);
    setStep(1);
  }

  return (
    <div className="ai-prompt-page">
      
        {step === 1 && (
          <div className="ai-prompt-container">
            <div className="ai-prompt-text-box">
              <Form.Control 
                as="textarea" 
                className="ai-prompt-text-box-input"
                placeholder="Describe your event in a few words. E.g. 'My birthday party'" 
                value={firstInput} 
                onChange={(e) => setFirstInput(e.target.value)} 
              />
            </div>
            <Button className="ai-prompt-btn" onClick={() => setStep(2)}>
              Submit
            </Button>
          </div>
        )}

        {step === 2 && (
          <div className="ai-prompt-container">
            <div className="ai-prompt-text-box">
              <Form.Control 
                as="textarea" 
                className="ai-prompt-text-box-input"
                placeholder="Any rough dates and times in mind? E.g. 'Weekend brunch' or 'Coming saturday'" 
                value={secondInput} 
                onChange={(e) => setSecondInput(e.target.value)} 
              />
            </div>
              <Button className="ai-prompt-btn" onClick={handleSecondSubmit}>
                Submit
              </Button>
          </div>
        )}

        {step === 3 && payload && (
          <div className="ai-prompt-container">
            <pre>{JSON.stringify(payload, null, 2)}</pre>
            <Button onClick={handleReset}>
              Reset
            </Button>
          </div>
        )}
      
    </div>

  );
  

  /*
  const [request, setRequest] = useState('');
  const { userData } = useUserContext();

  const handleSubmit = async () => {
    if (!request) return;

    try {
      // Process the request using OpenAI (or your backend that will in turn use OpenAI)
      const payload = { text: request };  // Replace with actual payload structure

      // This is a mockup API endpoint, replace it with your actual endpoint
      const response = await axios.post('/api/generateEventDraft', payload);

      if (response.data.success) {
        // Do something after successful API call, maybe route to the draft event page
        console.log('Event draft created:', response.data);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <div className="ai-prompt-page">
      <Card className="ai-prompt-container">
        <Card.Body>
          <div className="ai-prompt-text">
            <Form className="text-promtp">
              <Form.Group>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Describe your event in a few words. E.g. 'My birthday party'" 
                  value={request}
                  onChange={e => setRequest(e.target.value)}
                />
              </Form.Group>
            </Form>
            <Button className="ai-prompt-btn" onClick={handleSubmit}>
              <IoIosColorWand />
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
  */
};

export default FirstPage;
