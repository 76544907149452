import React, { useState } from 'react';
import './confirmableaction.css';

const ConfirmableAction = ({ children, onConfirm, confirmMessage }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleConfirm = () => {
    onConfirm();
    setShowModal(false);
  };

  const handleDecline = () => {
    setShowModal(false);
  };

  return (
    <div className="action-btn">
      <div onClick={handleShowModal}>
        {children}
      </div>
      {showModal && (
        <div className="confirmation-modal">
          <p>{confirmMessage || 'Are you sure?'}</p>
          <button onClick={handleConfirm}>Yes</button>
          <button onClick={handleDecline}>No</button>
        </div>
      )}
    </div>
  );
};

export default ConfirmableAction;
