import React, { useEffect, useRef } from 'react';
import { PiCalendarXThin, PiUserCircleThin, PiSignOutThin } from 'react-icons/pi';

import './css/avatarmenu.css'
import Avvvatars from 'avvvatars-react'
import { useUserContext } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';

function AvatarMenu({ toggleCreateEventPopup, toggleProfilePopup, onLogout, menuOpen, setMenuOpen }) {
  const toggleMenu = () => {
    setMenuOpen(prev => !prev);
  };
  const { userData, fetchUserProfile } = useUserContext();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const wrapperRef = useRef(null);

  const generateAvatar = (user) => {
    const { first_name, last_name, id } = user;

    // Check if both first and last name are present
    if (first_name && last_name) {
      const displayValue = `${first_name[0].toUpperCase()}${last_name[0].toUpperCase()}`;
      return <Avvvatars value={displayValue} displayValue={displayValue} size={40} />;
    }

    // Check if at least one name is present
    if (first_name || last_name) {
      const displayValue = (first_name || last_name)[0].toUpperCase();
      return <Avvvatars value={displayValue} displayValue={displayValue} size={40} />;
    }

    // If neither first nor last name are present, use the id to generate a random shape
    return <Avvvatars style="shape" value={id} size={40} />;
  };

  useEffect(() => {
    if (loading) {
      fetchUserProfile().then(() => {
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }

    // Attach the click event handler
    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setMenuOpen]);

  return (
    <div className="avatar-menu-wrapper" ref={wrapperRef}>
      {/* Clicking on this button will toggle the menu */}
      <button
        className="avatar-menu"
        onClick={toggleMenu}
        aria-haspopup="true"
        aria-expanded={menuOpen}
      >
        <div className='avatar-menu-avatar'>
          {generateAvatar(userData)}
        </div>
        <div className='avatar-user-info'>
          <div style={{ textAlign: 'left' }}>
            <small>
              {userData.first_name || userData.last_name ? `${userData.first_name || ''} ${userData.last_name || ''}`.trim() : null}
            </small>
          </div>
          <div style={{ textAlign: 'left' }}><small>{userData.primary_phone_number_e164 ?
            userData.primary_phone_number_e164 : null}
          </small>
          </div>
        </div>
      </button>

      {menuOpen && (
        <div className="avatar-dropdown-menu">
          <div onClick={toggleProfilePopup} className="menu-item"><PiUserCircleThin size={20} />  Profile</div>
          <div onClick={() => navigate('/expired')} className="menu-item"><PiCalendarXThin size={20} />  Expired Events</div>
          <div onClick={onLogout} className="menu-item"><PiSignOutThin size={20} />  Logout</div>
        </div>
      )}
    </div>
  );
}

export default AvatarMenu;