import { useEffect, useState } from 'react';
import Avvvatars from 'avvvatars-react'
import './css/contactpage.css';

const ContactPage = () => {
  const [friends, setFriends] = useState([]);

  useEffect(() => {
    // Generate stub data
    const generatedFriends = Array.from({ length: 25 }, (_, i) => {
      const tags = ['Friend', 'Family', 'Work', 'School'];
      const randomTags = tags.sort(() => 0.5 - Math.random()).slice(0, Math.floor(Math.random() * tags.length));
      return {
        id: i,
        name: `Friend ${i + 1}`,
        tags: randomTags
      };
    });

    setFriends(generatedFriends);
  }, []);

  const tag = (tag) => {
    return (
      <div className="friend-tag">
        {tag}
      </div>)
  }

  return (
    <div className="contact-page-container">
      <div className="contact-page-header">
        You have {friends.length} friends
      </div>

      <div id="friend-list">
        {friends.map(friend => (
          <div className="friend-row" key={friend.id}>
            <Avvvatars value={friend.name} displayValue={friend.name} size={40} />
            <div className="friend-info">
              <div className="friend-name">{friend.name}</div>
              <div className="friend-tags">
                {friend.tags.map(t => tag(t))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactPage;