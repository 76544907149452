import React, { useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import './css/SimpleForm.css'; // Custom styles for the form
import './css/UserPhoneRow.css';
import PhoneInput from 'react-phone-input-2';

const UserPhoneRow = ({ row, onChange, onRemove }) => {
  const handleInputChange = (field, value) => {
    onChange(row.id, { ...row, [field]: value });
  };

  const handleRemoveClick = () => {
    const isConfirmed = window.confirm("Are you sure you want to remove this participant?");
    if (isConfirmed) {
      onRemove(row.id);
    }
  };

  return (
    <div className="participant-data">
      <div className="row-component">
        <Form.Control
          type="text"
          placeholder="First Name"
          value={row.first_name || ' '}
          onChange={(e) => handleInputChange('first_name', e.target.value)}
          required
          className="name-input"
          isInvalid={!row.first_name}
        />
        <Form.Control
          type="text"
          placeholder="Last Name"
          value={row.last_name || ' '}
          onChange={(e) => handleInputChange('last_name', e.target.value)}
          required
          className="name-input"
          isInvalid={!row.last_name}
        />
      </div>
      <PhoneInput
        className="phone-input"
        country={'us'}
        value={row.phone_number || ''}
        placeholder='Phone Number'
        onChange={(value) => handleInputChange('phone_number', '+' + value)}
        countryCodeEditable={false}
        international={true}
      />
      <Button variant="danger" onClick={handleRemoveClick}>
        Remove
      </Button>
    </div>
  );
};

export default UserPhoneRow;
