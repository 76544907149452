import React, { useState } from 'react';
import './location.css'

import PlacesAutoCompleteComp from '../PlacesAutoCompleteComp';

const LocationSearchModal = ({ onClose, setLocation }) => {

  const [locationState, setLocationState] = useState(null);

  const onSelectLocation = (googlePlace) => {
    if (!googlePlace) return;
    setLocationState(googlePlace);
  }

  const handleLocationSelect = () => {
    setLocation(locationState);
    onClose();
  };

  return (
    <div className="location-search-modal">
      <div className="location-modal-container">
        <div className="location-modal-header">
          <h3>Select Location</h3>
          <button className="close-button" onClick={onClose}>
            Close
          </button>
        </div>

        <div className="locatioan-modal-content">
          <div className="autocomplete-section">
            <PlacesAutoCompleteComp setAddress={onSelectLocation} />
          </div>
        </div>

        <div className="location-modal-footer">
          <button className="save-button" onClick={handleLocationSelect}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default LocationSearchModal;
