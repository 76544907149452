import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import '../css/addpersonmodal.css'

const AddPersonModal = ({ isOpen, onClose, onAddPerson, type }) => {
  const [newPerson, setNewPerson] = useState({ first_name: '', last_name: '', phone: '' });

  const handleAddPerson = () => {
    setNewPerson({ first_name: '', last_name: '', phone: '' });
    onAddPerson(newPerson, type);
    onClose();
  };

  return isOpen ? (
    <div className="add-person-modal">
      <h5>Add {type}</h5>
      <input
        type="text"
        placeholder="First Name"
        value={newPerson.first_name}
        onChange={e => setNewPerson(prev => ({ ...prev, first_name: e.target.value }))}
      />
      <input
        type="text"
        placeholder="Last Name"
        value={newPerson.last_name}
        onChange={e => setNewPerson(prev => ({ ...prev, last_name: e.target.value }))}
      />
      <PhoneInput
        className="phone-input"
        country={'us'}
        value={newPerson.phone || ''}
        placeholder='Phone Number'
        onChange={e => setNewPerson(prev => ({ ...prev, phone: e }))}
        countryCodeEditable={false}
        international={true}
      />
      <button className="person-btn" onClick={handleAddPerson}>Add</button>
      <button className="person-btn" onClick={onClose}>Cancel</button>
    </div>
  ) : null;
};

export default AddPersonModal;
