import React from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';

const ProtectedRoute = ({ authenticated, element }) => {
  const location = useLocation();

  if (!authenticated) {
    // Redirect to login page
    const redirectTo = encodeURIComponent(location.pathname);
    return <Navigate to={`/login?redirect=${redirectTo}`} replace />;
  }

  return element;
};

export default ProtectedRoute;
