import React, { useState } from 'react';
import './dateTimePickerModal.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateTimePickerModal = ({ onClose, currentDateTime, setDateTime }) => {

  const [dateTimeState, setDateTimeState] = useState(new Date(currentDateTime));

  const onSelectDateTime = (date) => {
    if (!date) return;
    setDateTimeState(date);
  };

  const handleDateTimeSelect = () => {
    setDateTime(dateTimeState);
    onClose();
  };

  const today = new Date();

  return (
    <div className="date-time-picker-modal">
      <div className="date-time-modal-container">
        <div className="date-time-modal-header">
          <h3>Select Date and Time</h3>
          <button className="close-button" onClick={onClose}>
            Close
          </button>
        </div>

        <div className="date-time-modal-content">
          <DatePicker
            selected={dateTimeState}
            onChange={onSelectDateTime}
            showTimeSelect
            dateFormat="Pp"
            minDate={today}
          />
        </div>

        <div className="date-time-modal-footer">
          <button className="save-button" onClick={handleDateTimeSelect}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default DateTimePickerModal;