import React from 'react';
import './attendeemodal.css';

const AttendeeModal = ({ isOpen, onClose, attendees }) => {
  const closeModalOnClickOutside = (event) => {
    if (event.target.className !== 'attendee-detail-modal') {
      onClose();
    }
  };

  return isOpen ? (
    <div
      className='attendee-detail-modal'
      onClick={closeModalOnClickOutside}
    >
      <div className='attendee-detail-modal-content'>
        <h2>Attendee List</h2>
        <ul>
          {attendees && attendees.length > 0 ? (
            attendees.map((attendee) => (
              <li key={attendee.id}>
                {`${attendee.first_name} ${attendee.last_name} ${attendee.status}`}
              </li>
            ))
          ) : (
            <p>No attendees.</p>
          )}
        </ul>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  ) : null;
};

export default AttendeeModal;
