import NewEventCard from "./NewEventCard";
import './css/eventcards.css'
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { AxiosContext } from '../context/AxiosContext';
import debug from '../debug_tools/debugLogger'
import { useUserContext } from '../context/UserContext';
import JeevesRequestUtils from '../context/JeevesRequestUtils';
import { useEventDataContext } from '../context/EventDataContext';

const EventCards = ({ onEventUpdate }) => {
    const { eventData, refreshEventData } = useEventDataContext();
    const [hostedEvents, setHostedEvents] = useState([]);
    const [draftEvents, setDraftEvents] = useState([]);
    const { authAxios } = useContext(AxiosContext);
    const { userData } = useUserContext();

    const [invitedEvents, setInvitedEvents] = useState([]);

    const { handleEventPublish,
        handleEventCancel,
        handleEventDelete,
        handleEventRSVP,
        handleEventUpdate } = JeevesRequestUtils();

    useEffect(() => {
        if (!eventData) {
            return;
        }
        let { created_events, co_hosted_events, invited_events } = eventData.data;
        setInvitedEvents(invited_events);
        if (created_events && co_hosted_events) {
            let res = created_events.concat(co_hosted_events);
            let drafts = res.filter(event => event.event_state === "draft");
            setDraftEvents(drafts);
            let publishedEvents = res.filter(event => event.event_state === "published");
            setHostedEvents(publishedEvents);

            debug.group('fetched events:', {
                hosted_events: JSON.stringify(publishedEvents),
                drafted_events: JSON.stringify(drafts),
                created_events: JSON.stringify(created_events),
                invited_events: JSON.stringify(invited_events)
            })
        }
    }, [eventData])

    const getColor = (state) => {
        switch (state) {
            case 'YES':
                return 'accepted'
            case 'NO':
                return 'declined'
            case 'EXPIRED':
                return 'expired'
            default:
                return 'default'
        }
    }

    function isEventExpired(card) {
        const eventStartTime = new Date(card.start_time);
        const currentTime = new Date();

        return eventStartTime < currentTime;
    }

    function getEventCardColor(card) {
        if (isEventExpired(card)) {
            return 'expired';
        }
        if (!card.invites[0] || !card.invites[0].rsvp_state) {
            return 'default';
        }
        return getColor(card.invites[0].rsvp_state);
    }

    return (
        <div className="event-cards-wrapper">
            <h3 className="event-cards-title">Your Events</h3>
            {/* Added Title */}
            <div className="event-cards-container">
                {
                    hostedEvents &&
                    hostedEvents
                        .filter(card => !isEventExpired(card))
                        .map((card, index) => {
                            return <NewEventCard
                                key={card.id || index}
                                cardData={card}
                                color={getEventCardColor(card)}/>;
                        })
                }
            </div>
            <h3 className="event-cards-title">Your Invitations</h3>
            {/* Added Title */}
            <div className="event-cards-container">
                {
                    invitedEvents &&
                    invitedEvents
                        .filter(card => !isEventExpired(card))
                        .map((card, index) => {
                            return <NewEventCard
                                key={card.id || index}
                                cardData={card}
                                color={getEventCardColor(card)}
                                type={"invited"} />;
                        })
                }
            </div>
            <h3 className="event-cards-title">Your Drafts</h3>
            {/* Added Title */}
            <div className="event-cards-container">
                {
                    draftEvents &&
                    draftEvents.map((card, index) => {
                        const color = 'default';
                        return <NewEventCard
                            key={card.id || index}
                            cardData={card}
                            color={color}
                            />;
                    })
                }
            </div>
        </div>
    );

}

export default EventCards;