import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext({
  authState: null, // Default value
  setSessionInLocalStorage: () => { },
  logout: () => { },
  authenticated: () => false,
  loading: true,
});

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    phone_number: null,
    refresh: null,
    access: null,
    newly_created: null,
  });
  const authenticated = () => authState.access !== null;
  const [loading, setLoading] = useState(true);  // Corrected to array destructuring

  useEffect(() => {
    loadSessionFromLocalStorage().then(() => setLoading(false)); // Assuming loadSessionFromLocalStorage is async
  }, []);

  useEffect(() => {
    if (authState && authState.access !== null) { // You can tailor this condition based on what "fully loaded" means in your context
      setLoading(false);
    }
  }, [authState]);

  useEffect(() => {
  }, [loading])

  const loadSessionFromLocalStorage = async () => {
    const session = localStorage.getItem('session');
    if (session) {
      setAuthState(JSON.parse(session));
    }
    setLoading(false); // Move this here
  };

  const setSessionInLocalStorage = (session) => {
    localStorage.setItem('session', JSON.stringify(session));
    setAuthState(session);
  };

  const logout = () => {
    localStorage.removeItem('session');
    setAuthState({ phone_number: null, refresh: null, access: null, newly_created: null });
  };

  return (
    <AuthContext.Provider value={{ authState, setSessionInLocalStorage, logout, authenticated, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
