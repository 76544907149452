import React, { useState, useEffect } from 'react';
import './loading.css'

const LoadingScreen = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 1000); // 1000ms = 1 second

    return () => clearTimeout(timer);
  }, []);

  return (
    isVisible && (
      <div className="loading-screen">
        <div className="spinner"></div> {/* Add your spinner styling here */}
      </div>
    )
  );
};

export default LoadingScreen;
