import React, { createContext, useState, useContext, useEffect } from 'react';
import JeevesRequestUtils from './JeevesRequestUtils';
import { AxiosContext } from './AxiosContext';
import debug from '../debug_tools/debugLogger'

const EventDataContext = createContext();

export const useEventDataContext = () => {
  return useContext(EventDataContext);
};

export const EventDataProvider = ({ children }) => {
  const [eventData, setEventData] = useState(null);
  const { fetchData } = JeevesRequestUtils();
  const { tokenRefreshed } = useContext(AxiosContext);

  useEffect(() => {
    // Fetch initial data
    if (!eventData) {
      fetchData()
        .then((resp) => {
          setEventData(resp);
        })
    }
    
    // Set up an interval to refresh event data every minute (60000 milliseconds)
    const intervalId = setInterval(() => {
      refreshEventData();
    }, 300000); // 5min
    
    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
    
  }, [eventData]);

  useEffect(() => {
    if (tokenRefreshed) {
      refreshEventData();
    }
  }, [tokenRefreshed]);

  const refreshEventData = () => {
    fetchData()
      .then((resp) => {
        debug.log('refreshing event data:', JSON.stringify(resp));
        setEventData(resp);
      })
  };

  const value = {
    eventData,
    setEventData,
    refreshEventData,
  };

  return (
    <EventDataContext.Provider value={value}>
      {children}
    </EventDataContext.Provider>
  );
};
