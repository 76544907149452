import SimpleForm from "./SimpleForm";
import { Modal } from "react-bootstrap";

const CreateEventPopup = ({ isOpen, onClose }) => {
  return (
    <Modal show={isOpen} onHide={onClose} onExited={() => {console.log('nothing')}}>
       <Modal.Header closeButton>
        <Modal.Title>Create an Event</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SimpleForm cb={onClose} />
      </Modal.Body>
    </Modal>
  );
}

export default CreateEventPopup;
