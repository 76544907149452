import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Avatar from 'react-avatar';
import { IoIosAdd } from 'react-icons/io';

const ChatWindow = () => {
  // This is just a stub for the sake of demonstration.
  const messages = [
    { id: 1, sender: 'Alice', text: 'Hey, are you coming to the event?' },
    { id: 2, sender: 'Bob', text: 'Yes, I am! Looking forward to it.' },
    { id: 3, sender: 'Alice', text: 'Great! See you there.' },
  ];

  return (
    <div className="chat-window">
      {messages.map(message => (
        <div key={message.id} className="message">
          <strong>{message.sender}:</strong> {message.text}
        </div>
      ))}
    </div>
  );
};

{/*
{
  "id": "WKGUNr9SFDk",
  "created_by": {
    "id": "nhvblbJkOiC",
    "username": "+14088813331",
    "email": ""
  },
  "co_hosts": [],
  "invites": [],
  "creator_phone_number": "+14088813331",
  "title": "titile 2",
  "description": "hello",
  "image": null,
  "start_time": "2023-08-12T23:30:00Z",
  "end_time": "2023-08-12T23:30:00Z",
  "event_state": "draft",
  "event_visibility": "invite_only",
  "venue_str": "",
  "venue_formatted_address": "Danville, CA, USA",
  "getting_there_instructions": "ins",
  "additional_instructions": "add",
  "max_participants": null,
  "shareable_url": "http://jeeves.fly.dev/api/events/WKGUNr9SFDk/",
  "passcode": "",
  "created_at": "2023-08-12T23:53:17.509013Z",
  "updated_at": "2023-08-12T23:53:17.518542Z",
  "is_deleted": false,
  "address_details": null,
  "categories": []
}*/
}

const EventScreen = () => {
  const eventData = {
    description: `
    
Details:

Hey there Quantum Whizzes!

With midterms just around the corner, we know how daunting Quantum Mechanics can get. But why venture into the world of quarks, superpositions, and quantum entanglement alone when you can dive deep with fellow learners?

Join us at the Feynman Science & Technology Building A for a collaborative study session. Whether you're feeling lost in wave-particle duality, need a refresher on Schrödinger's equation, or just want to discuss mind-bending quantum concepts, this is the place to be!

Why attend?
🌟 Collaborate and clarify doubts.
🌟 Share notes and resources.
🌟 Engage in thought-provoking quantum discussions.
🌟 Find study buddies for those late-night cramming sessions.

What to Bring:
- Textbooks, notes, and study materials.
- Laptops or tablets if you prefer digital resources.
- Snacks and drinks to share! (Let's make it fun!)
- Your puzzling quantum questions and unbridled enthusiasm.

    `,
    date: 'August 15, 2023',
    time: '6:00 PM',
    venue_str: 'Feyman Science Building A',
    address: '123 Event St, EventCity, EV 12345'
  };

  return (
    <div className="event-screen">
      <h1>hello</h1>
    </div>
  );
};


export default EventScreen;