import React, { useEffect, useState } from 'react';
import './css/EventDetail.css';
import { IoIosCalendar, IoIosTimer } from 'react-icons/io';
import { PiUserListThin } from 'react-icons/pi';
import { BsPersonAdd } from 'react-icons/bs';
import titleImage from '../assets/images/img1.jpeg';
import Avvvatars from 'avvvatars-react';
import InlineEditable from './utils/InlineEditable';
import LocationSearchModal from './modals/LocationSearchModal';
import DateTimePickerModal from './modals/DateTimePickerModal';
import AddPersonModal from './utils/AddPersonModal';
import AttendeeModal from './utils/AttendeeModal';

// Helpers
const generateAvatar = (user) => {
  const { first_name, last_name, id } = user;

  // Check if both first and last name are present
  if (first_name && last_name) {
    const displayValue = `${first_name[0].toUpperCase()}${last_name[0].toUpperCase()}`;
    return <Avvvatars value={displayValue} displayValue={displayValue} size={40} />;
  }

  // Check if at least one name is present
  if (first_name || last_name) {
    const displayValue = (first_name || last_name)[0].toUpperCase();
    return <Avvvatars value={displayValue} displayValue={displayValue} size={40} />;
  }

  // If neither first nor last name are present, use the id to generate a random shape
  return <Avvvatars style="shape" value={id} size={40} />;
};

const preparePeopleList = (people, status = 'HOST') => {
  if (!people || !Array.isArray(people)) {
    return [];
  }

  let res = people.map(person => {
    return {
      first_name: person.first_name,
      last_name: person.last_name,
      id: person.id,
      phone: person.primary_phone_number_e164,
      status: person.rsvp_state || status,
    };
  });
  return res;
};

// Sub-components
const PeopleList = ({ list, onAddClick, onDeleteClick, editable, mode = "Invite" }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);

  const handleClick = (person) => {
    setSelectedPerson(person);
    setShowModal(true);
  };

  const handleRemoveClick = () => {
    onDeleteClick(selectedPerson); // Assuming onDeleteClick takes a person object as argument
    setShowModal(false); // Close the modal after removal
  };

  return (
    <div className="people-list-container">
      {list && list.map((person, index) => {
        return (
          <div
            key={index}
            title={person.name || person.rsvp_state}
            onClick={() => handleClick(person)}
          >
            {generateAvatar(person)}
          </div>
        )
      })}
      {editable && (
        <div onClick={onAddClick} className="add-people-icon">
          <BsPersonAdd className="event-title-avatar-add" size={20} />{mode}
        </div>
      )}

      {showModal && (
        <>
          <div className="people-modal-backdrop" onClick={() => setShowModal(false)}></div>
          <div className="attendee-detail-modal">
            <p>{
              selectedPerson ? (
                selectedPerson.first_name || selectedPerson.last_name
                  ? `${selectedPerson.first_name || ''} ${selectedPerson.last_name || ''}`.trim()
                  : selectedPerson.phone || "Unknown"
              ) : "Unknown"
            }</p>
            <p>Status: {selectedPerson ? selectedPerson.status : "Unknown"}</p>
            <button onClick={() => setShowModal(false)}>Close</button>
            {editable && ( // Only render the "Remove" button if editable is true
              <button onClick={handleRemoveClick}>Remove</button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const EventDetail = ({ eventData, isHost, eventDetailState, setIndividualField }) => {
  const [newAttendee, setNewAttendee] = useState({ first_name: '', last_name: '', phone: '' });

  const [editingField, setEditingField] = useState(null);

  const [locationModal, setLocationModal] = useState(false);
  const [showDatePickerModal, setShowDatePickerModal] = useState(false);
  const [showAttendeeModal, setShowAttendeeModal] = useState(false);

  const renderAdditionalField = (fieldName, title, placeholder) => {
    const toggleEditing = () => {
      if (editingField === fieldName) {
        // Clear and close the editable field if it's already open
        setEditingField(null);
        setIndividualField(fieldName, '');
      } else {
        setEditingField(fieldName);
        setIndividualField(fieldName, '');
      }
    };

    return (
      <div className="additional-details">
        {(eventDetailState[fieldName] !== "" || editingField === fieldName) &&
          <h5 onClick={toggleEditing}>{title}</h5>
        }
        {(eventDetailState[fieldName] === "" && editingField !== fieldName) ? (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              toggleEditing();
            }}
          >
            + {title}
          </a>
        ) : (
          <InlineEditable
            value={eventDetailState[fieldName]}
            setValue={(val) => {
              setIndividualField(fieldName, val);
              if (val.trim() === '') {
                setEditingField(null);
              }
            }}
            multiline={true}
            editable={isHost}
            placeholder={placeholder}
          />
        )}
      </div>
    );
  };

  const [showPersonModal, setShowPersonModal] = useState(false);
  const [personType, setPersonType] = useState('Attendee'); // or 'CoHost'

  const handleAddPerson = (person, type) => {
    if (type === 'Attendee') {
      handleAddAttendee(person);
    } else if (type === 'CoHost') {
      handleAddCohost(person)
    }
  };

  const hostsData = [eventData.created_by].concat(eventDetailState.co_hosts || []);
  const hostsList = preparePeopleList(hostsData, 'HOST');

  const invitees = eventDetailState.invites
    .filter(v => v !== null)
    .map(invite => ({
      ...invite.invitee,
      rsvp_state: invite.rsvp_state
    }));

  const attendeeList = preparePeopleList(invitees, 'INVITED');

  const handleAddAttendee = (person) => {
    const attendeeToAdd = {
      rsvp_state: "unseen",
      invitee: {
        first_name: person.first_name,
        last_name: person.last_name,
        username: person.phone
      }
    };
    setIndividualField('invites', [...(eventDetailState.invites || []), attendeeToAdd]);
  };

  const handleAddCohost = (person) => {
    const cohostToAdd = {
      first_name: person.first_name,
      last_name: person.last_name,
      username: person.phone
    };
    setIndividualField('co_hosts', [...(eventDetailState.co_hosts || []), cohostToAdd]);
  }

  const handleTimeChange = (date) => {
    setShowDatePickerModal(true);
  };

  const handleLocationChange = () => {
    setLocationModal(true);
  }

  const handleModalClose = () => {
    setLocationModal(false);
    setShowDatePickerModal(false);
  }

  const formatDisplayAddress = (address) => {
    if (!address) return null;
    let addressParts = address.split(',');

    let street = addressParts[0];
    let rest = addressParts.slice(1).join(',').trim();
    return (address ? (
      <>
        <div className='venue-name'>
          <span>{eventData.venue_str || ""}</span>
        </div>
        <div className="address">
          <span>{street}</span><br />
          <span>{rest ? rest.trim() : ''}</span>
        </div>
      </>
    ) : (
      <span>Location TBD</span>
    ));
  }

  const generateGoogleMapsUrl = (address) => {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
  };

  const saveNewLocation = (val) => {
    setIndividualField('venue_formatted_address', val.formatted_address === null ? "no location" : val.formatted_address)
    setIndividualField('venue_str', val.name)
  }

  const saveNewTime = (date) => {
    setIndividualField('start_time', date)
  }

  return (
    <div className="event-screen-container">
      <div className="event-cover-photo">
        <img src={titleImage} alt="Event Cover" className="user-image" />
      </div>

      <div className="event-title">
        {eventData.title !== "not populated yet" && (

          <div className="event-title-text editable-event-component">
            <h1><InlineEditable editable={isHost} value={eventDetailState.title} setValue={(val) => setIndividualField('title', val)} editing={isHost} /></h1>
          </div>
        )}
        <div className="event-title-avatar">
          <PeopleList
            list={hostsList}
            editable={isHost}
            onAddClick={() => {
              setPersonType('CoHost');
              setShowPersonModal(true);
            }}
            mode={""}
          />
        </div>
      </div>

      {/* Separation Line */}
      <div className="separator"></div>

      {/* Top Row */}
      <div className="event-details">
        {/* Left Side - Date & Time */}
        <div className='event-date-time'>
          <div className={`event-date-time-details ${isHost ? 'event-location-time--editable' : ''}`}
            onClick={isHost ? handleTimeChange : undefined}>
            <p><IoIosCalendar /> {eventDetailState.start_time ? new Date(eventDetailState.start_time).toLocaleDateString() : "Date TBD"}</p>
            <span><IoIosTimer /> {eventDetailState.start_time ? new Date(eventDetailState.start_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : "Time TBD"}</span>
          </div>
        </div>

        {/* Separation Line */}
        <div className="separator"></div>

        {/* Right Side - Venue & Address */}
        <div className="venue-details" >
          <div className={`event-location-details ${isHost ? 'event-location-time--editable' : ''}`}
            onClick={isHost ? handleLocationChange : undefined}>
            <span>{eventDetailState.venue_str}</span>
            <span>{formatDisplayAddress(eventDetailState.venue_formatted_address)}</span>
          </div>
          <div className="links">
            {eventDetailState.venue_formatted_address &&
              <a href={generateGoogleMapsUrl(eventDetailState.venue_formatted_address)} target="_blank" rel="noopener noreferrer">
                Google Map
              </a>
            }
            {eventData.start_time && <a href="#">Add to Calendar</a>}
          </div>
        </div>
      </div>

      {/* Separation Line */}
      <div className="horizontal-separator"></div>
      {eventData.description && (
        <div className="event-description">
          <InlineEditable
            value={eventDetailState.description}
            setValue={(val) => setIndividualField('description', val)}
            editable={isHost}
            rows={eventData.description.split('\n').length}
            multiline={true}
            editing={isHost} />
        </div>
      )}

      {/* Separation Line */}
      <div className="horizontal-separator"></div>

      {/* Attendees (Stubbed for the sake of this example) */}
      <div className="attendees">
        <button
          onClick={() => {
            setShowAttendeeModal(!showAttendeeModal);
          }}
          className="add-invites-icon"
        >
          <PiUserListThin size={30} />
        </button>
        {isHost || attendeeList && attendeeList.length > 0 ? (
          <PeopleList
            list={attendeeList}
            editable={isHost}
            onAddClick={() => {
              setPersonType('Attendee');
              setShowPersonModal(true);
            }}
          />
        ) : (
          <p>No one was invited.</p>
        )}
        <AttendeeModal
          isOpen={showAttendeeModal}
          onClose={() => setShowAttendeeModal(false)}
          attendees={attendeeList}
        />
        <AddPersonModal
          isOpen={showPersonModal}
          onClose={() => setShowPersonModal(false)}
          onAddPerson={handleAddPerson}
          type={personType}
        />
      </div>

      <div className="horizontal-separator"></div>

      <div className="event-meta-instructions">
        {renderAdditionalField('getting_there_instructions', 'Getting There Instructions', 'Enter getting there instructions here')}
        {renderAdditionalField('additional_instructions', 'Additional Instructions', 'Enter additional instructions here')}
      </div>
      {!!locationModal ? <LocationSearchModal
        isOpen={locationModal}
        onClose={handleModalClose}
        setLocation={saveNewLocation} /> : null}

      {showDatePickerModal && (
        <DateTimePickerModal
          onClose={handleModalClose}
          setDateTime={saveNewTime}
          currentDateTime={eventDetailState.start_time}
        />
      )}
    </div>

  );
};

export default EventDetail;