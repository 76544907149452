import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import debug from '../debug_tools/debugLogger'

export const AxiosContext = createContext();

export const AxiosProvider = ({ children }) => {
  const { authState, setSessionInLocalStorage, logout } = useContext(AuthContext);
  const [tokenRefreshed, setTokenRefreshed] = useState(false);

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL + '/api',
  });

  authAxios.interceptors.request.use(
    config => {
      if (authState.access) {
        config.headers.Authorization = `Bearer ${authState.access}`;
      }

      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      //config.headers['User-Timezone'] = userTimezone;

      debug.group(`Request Info: ${config.url}`, {
        'Method': config.method,
        'Headers': JSON.stringify(config.headers, null, 2),
        'Data': JSON.stringify(config.data, null, 2)
      });

      return config;
    },
    error => {
      debug.group('Request Error', {
        'Failed request': error.response ? error.response.data.message : null,
        'Status': error.response ? error.response.status : null,
        'Headers': error.response ? error.response.headers : null,
        'No response received': error.request ? error.request : null,
        'Setup Error': !error.response && !error.request ? error.message : null
      });

      return Promise.reject(error);
    },
  );

  authAxios.interceptors.response.use(
    response => {
      debug.group(`Response Info: ${response.config.url}`, {
        'Status': response.status,
        'Data': JSON.stringify(response.data, null, 2),
        'Headers': JSON.stringify(response.headers, null, 2)
      });
      return response;
    },
    async error => {
      debug.group('Response Error', {
        'Failed response': error.response ? error.response.data.message : null,
        'Status': error.response ? error.response.status : null,
        'Headers': error.response ? error.response.headers : null,
        'No response received': error.request ? error.request : null,
        'Setup Error': !error.response && !error.request ? error.message : null
      });
      return Promise.reject(error);
    }
  );

  const refreshAuthLogic = failedRequest => {
    debug.log('token state: ', JSON.stringify(authState))
    return axios.post('https://jeeves.fly.dev/api/token/refresh/', {
      refresh: authState.refresh,
    })
      .then(tokenRefreshResponse => {
        debug.log('tokenrefresh' + JSON.stringify(tokenRefreshResponse))

        if (!tokenRefreshResponse.data || !tokenRefreshResponse.data.access) {
          debug.log('Unexpected token refresh response', tokenRefreshResponse);
          logout();
          return Promise.reject();
        }

        const { access: accessToken } = tokenRefreshResponse.data;

        authState.access = accessToken;
        failedRequest.response.config.headers.Authorization = 'Bearer ' + accessToken;

        setSessionInLocalStorage(authState);
        setTokenRefreshed(true);
        return authAxios(failedRequest.config);
      })
      .catch(e => {
        debug.log('error refreshing token: ', e)
        logout();
        return Promise.reject();
      });
  };

  createAuthRefreshInterceptor(authAxios, refreshAuthLogic);

  return (
    <AxiosContext.Provider value={{ authAxios, tokenRefreshed, setTokenRefreshed }}>
      {children}
    </AxiosContext.Provider>
  );
};
