import React, { useEffect, useState, useContext } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { AiOutlineCheck, AiOutlinePhone, AiOutlineWhatsApp } from 'react-icons/ai';
import { useUserContext } from '../context/UserContext';
import { AuthContext } from '../context/AuthContext';
import { AxiosContext } from '../context/AxiosContext';
import debug from '../debug_tools/debugLogger'

const ProfilePopup = ({ isOpen, onClose }) => {
  const { authState } = useContext(AuthContext);
  const { authAxios } = useContext(AxiosContext);
  const { userData, updateUserData } = useUserContext();
  const [profileData, setProfileData] = useState({
    id: userData?.id || null,
    phone_number: userData?.primary_phone_number_e164 || '',
    country_code: userData?.country_code || '',
    first_name: userData?.first_name || '',
    last_name: userData?.last_name || '',
    city: userData?.city || '',
    is_verified: userData?.is_verified || false,
    date_of_birth: userData?.date_of_birth || '',
    gender: userData?.gender || '',
    email: userData?.email || '',
    preferred_communication: userData?.preferredCommunication || 'phone',
  });

  const [changed, setChanged] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const isEmailValid = (email) => {
    // Regular expression for email validation
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const updatedProfileData = {
      id: profileData.id,
      phone_number: profileData.phone_number,
      country_code: profileData.country_code,
      first_name: profileData.first_name.trim(),
      last_name: profileData.last_name.trim(),
      city: profileData.city.trim(),
      is_verified: profileData.is_verified,
      date_of_birth: profileData.date_of_birth,
      gender: profileData.gender,
      email: profileData.email.trim()
    };
    authAxios.put(`/profile/${userData.id}/`, profileData)
      .catch((error) => {
        toast.error('Error saving profile information. Please try again later.' + JSON.stringify(error));
      });
    setChanged(true);
    updateUserData(updatedProfileData);
  };

  useEffect(() => {
    setProfileData({
      id: userData?.id || null,
      phone_number: userData?.primary_phone_number_e164 || '',
      country_code: userData?.country_code || '',
      first_name: userData?.first_name || '',
      last_name: userData?.last_name || '',
      city: userData?.city || '',
      is_verified: userData?.is_verified || false,
      date_of_birth: userData?.date_of_birth || '',
      gender: userData?.gender || '',
      email: userData?.email || '',
      preferred_communication: userData?.preferredCommunication || 'phone',
    });
  }, [userData]);

  useEffect(() => {
    if (changed) {
      console.log('changed')
      toast.success('Profile information saved!');
    }
    setChanged(false);
  }, [changed]);

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Fill out your profile information</Modal.Title>
        {profileData.is_verified && <AiOutlineCheck />} {/* Display the check mark if the user is verified */}
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row mb-3">
            <div className="col">
              <Form.Control
                type="text"
                name="first_name"
                value={profileData.first_name}
                onChange={handleChange}
                placeholder="First Name"
              />
            </div>
            <div className="col">
              <Form.Control
                type="text"
                name="last_name"
                value={profileData.last_name}
                onChange={handleChange}
                placeholder="Last Name"
              />
            </div>
          </div>
          <div className="mb-3">
            <PhoneInput
              name="phone_number"
              country={"us"}
              value={profileData.phone_number}
              onChange={(value, country, e) => {
                setProfileData(prev => ({ ...prev, phone_number: value, country_code: country.countryCode }))
              }}
              disableDropdown={true}
            />
          </div>
          <div className="mb-3">
            <Form.Control
              type="text"
              name="email"
              value={profileData.email}
              onChange={handleChange}
              placeholder="E-mail"
              isInvalid={profileData && profileData.email && !isEmailValid(profileData.email)}
            />
          </div>
          <div className="row mb-3">
            <div className="col">
              <Form.Check
                type="radio"
                label={<AiOutlineWhatsApp />}
                name="preferredCommunication"
                value="whatsapp"
                checked={profileData.preferred_communication === 'whatsapp'}
                onChange={handleChange}
              />
            </div>
            <div className="col">
              <Form.Check
                type="radio"
                label={<AiOutlinePhone />}
                name="preferredCommunication"
                value="phone"
                checked={profileData.preferred_communication === 'phone'}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mb-3">
            {/* Profile picture uploader component */}
          </div>
          <div className="mb-3">
            <Form.Control
              type="text"
              name="city"
              value={profileData.city === 'unknown' ? '' : profileData.city}
              onChange={handleChange}
              placeholder="City"
            />
          </div>
          <div className="mb-3">
            <Form.Control
              type="date"
              name="date_of_birth"
              value={profileData.date_of_birth === '1800-01-01' ? '' : profileData.date_of_birth}
              onChange={handleChange}
              placeholder="Date of Birth"
            />
          </div>
          <div className="mb-3">
            <Form.Control
              type="text"
              name="gender"
              value={profileData.gender === 'empty' ? '' : profileData.gender}
              placeholder="Enter gender"
              onChange={handleChange}
            >
            </Form.Control>
            <button className="save-profile-btn btn btn-primary" onClick={handleSave}>Save</button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ProfilePopup;
