import { useEffect, useState } from 'react';
import { useEventDataContext } from '../context/EventDataContext';
import NewEventCard from './NewEventCard';
import './css/expiredevents.css';

const ExpiredEvent = () => {
  const { eventData } = useEventDataContext();
  const [expiredEvents, setExpiredEvents] = useState([]); // Corrected this line

  const findExpiredEvents = () => {
    if (!eventData || !eventData.data) {
      return [];
    }

    let { created_events, co_hosted_events, invited_events } = eventData.data;
    let expiredCreatedEvents = created_events ? created_events.filter(isEventExpired) : [];
    let expiredCoHostedEvents = co_hosted_events ? co_hosted_events.filter(isEventExpired) : [];
    let expiredInvitedEvents = invited_events ? invited_events.filter(isEventExpired) : [];
    let allExpiredEvents = [...expiredCreatedEvents, ...expiredCoHostedEvents, ...expiredInvitedEvents];

    const uniqueEvents = [];
    const seenEventIds = new Set();

    for (const event of allExpiredEvents) {
      if (!seenEventIds.has(event.id)) {
        seenEventIds.add(event.id);
        uniqueEvents.push(event);
      }
    }

    return uniqueEvents;
  }

  function isEventExpired(event) {
    const eventStartTime = new Date(event.start_time);
    const currentTime = new Date();

    return eventStartTime < currentTime;
  }

  useEffect(() => {
    if (!eventData || !eventData.data) {
      return;
    }
    setExpiredEvents(findExpiredEvents());
  }, [eventData]);

  return (
    <div className="expired-event-cards-wrapper">
      <div className="expired-event-cards-container">
        {expiredEvents.map((event, index) => (
          <div className="expired-event-card">
            <NewEventCard
              key={event.id}
              cardData={event} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default ExpiredEvent;
