import React from 'react';
import './loading.css'; // Where you define the `.spinner` class

const LoadableButton = ({ loading, onClick, children, ...rest }) => {
  console.log("loading: " + loading)
  return (
    <button onClick={onClick} {...rest}>
      {loading ? (
        <div className="spinner"></div>
      ) : (
        children
      )}
    </button>
  );
};

export default LoadableButton;
