import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { UserProvider } from './context/UserContext';
import { AuthProvider } from './context/AuthContext';
import { AxiosProvider } from './context/AxiosContext';

// Define the callback function outside of any component
window.myCallbackFunc = () => {
    // You can initialize your maps or places here...
};

// Check if the script is already loaded
if (!window.google) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places&callback=myCallbackFunc`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
} else {
    window.myCallbackFunc();
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <div>
        <AuthProvider>
            <AxiosProvider>
                <UserProvider>
                    <App />
                </UserProvider>
            </AxiosProvider>
        </AuthProvider>
    </div>
)