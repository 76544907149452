import React, { useEffect, useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Form from 'react-bootstrap/Form';

function PlacesAutoCompleteComp({setAddress}) { // Replace with your actual component name
  const [locationObject, setLocationObject] = useState('');

  const handleLocationSelect = (address) => {
    geocodeByAddress(address)
      .then(results => {
        setAddress(results[0]);
        setLocationObject(results[0].formatted_address);
       })
       .catch(error => {
        setAddress(null);
        setLocationObject('');
        console.error('Error', error)
       });
  };

  const handleError = (status, clearSuggestions) => {
    console.log('Error from Google Maps API', status);
    setLocationObject('');
    setAddress(null);
    clearSuggestions();
  }

  return (
    <Form.Group className='mb-3'>
      <PlacesAutocomplete
        value={locationObject}
        onChange={setLocationObject}
        onSelect={handleLocationSelect}
        onError={handleError}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Where do you want to meet',
                className: 'form-control mb-3 autocomplete-location',
                name: 'eventLocation'
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    key={suggestion.placeId}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </Form.Group>
  );
}

export default PlacesAutoCompleteComp; // Adjust to your component name if necessary
