import React, { useEffect, useContext } from 'react'
import EventCards from './EventCards.js';
import { AuthContext } from "../context/AuthContext";
import './css/home.css';
import { useUserContext } from '../context/UserContext';
import { AxiosContext } from '../context/AxiosContext';
import './css/home.css';
import debug from '../debug_tools/debugLogger'
import { toast } from 'react-hot-toast'
import { useEventDataContext } from '../context/EventDataContext';
import JeevesRequestUtils from '../context/JeevesRequestUtils.js';

const HomePage = () => {
  const { userData, updateUserData } = useUserContext();
  const { authenticated, authState } = useContext(AuthContext);
  const { authAxios } = useContext(AxiosContext);
  const { eventData } = useEventDataContext;
  const { fetchData } = JeevesRequestUtils();

  const onEventUpdate = (v) => {
    // refetch events when there's an update
    fetchData();
  }
  
  return (
    <>
        <div>
          <EventCards onEventUpdate={onEventUpdate} />
        </div>
    </>
  );
}

export default HomePage;