// debug.js
const isDevelopment = process.env.NODE_ENV === 'development';

const debug = {
  log: function (...args) {
    if (isDevelopment) {
      console.log(...args);
    }
  },
  warn: function (...args) {
    if (isDevelopment) {
      console.warn(...args);
    }
  },
  error: function (...args) {
    if (isDevelopment) {
      console.error(...args);
    }
  },
  info: function (...args) {
    if (isDevelopment) {
      console.info(...args);
    }
  },
  groupCollapsed: function (...args) {
    if (isDevelopment) {
      console.groupCollapsed(...args);
    }
  },
  groupEnd: function () {
    if (isDevelopment) {
      console.groupEnd();
    }
  },
  group: function(title, messages) {
    if (isDevelopment) {
        console.groupCollapsed(title);
        for (const key in messages) {
            console.log(`${key}:`, messages[key]);
        }
        console.groupEnd();
    }
}
};

export default debug;