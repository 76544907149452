import React, { useEffect, useContext, useState } from 'react'; // Import useState
import { useUserContext } from '../context/UserContext';
import { AxiosContext } from '../context/AxiosContext';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import EventDetail from './EventDetail';
import './css/EventPage.css';
import { FiThumbsUp, FiThumbsDown, FiX, FiTrash2, FiUpload, FiEdit3 } from 'react-icons/fi';
import { PiShareFatLight } from 'react-icons/pi';
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import JeevesRequestUtils from '../context/JeevesRequestUtils';
import debug from '../debug_tools/debugLogger';
import { useEventDataContext } from '../context/EventDataContext';
import ConfirmableAction from './utils/ConfirmableAction';
import LoadableButton from './utils/LoadableButton';
import LoadingScreen from './utils/LoadingScreen';
import ShareLinkModal from './modals/ShareLinkModal';

const EventPage = () => {
  const { authAxios } = useContext(AxiosContext);
  const { userData } = useUserContext();
  const { eventId } = useParams();
  const { authenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false); // New state variable

  const { handleEventRSVP, handleEventDelete, handleEventPublish, handleEventCancel, handleEventUpdate, fetchUserProfile } = JeevesRequestUtils();
  const { refreshEventData } = useEventDataContext() || {};

  const [refresh, setRefresh] = useState(false);

  const [eventData, setEventData] = useState(null); // Initialize eventData state
  const [eventDetailState, setEventDetailState] = useState({
    title: "",
    description: "",
    additional_instructions: "",
    getting_there_instructions: "",
    venue_formatted_address: "",
    venue_str: "",
    invites: [],
    co_hosts: [],
    start_time: null,
  });

  const openShareModal = () => {
    setIsShareModalOpen(true);
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false);
  };

  const shareEvent = () => {
    openShareModal();
  };

  const setIndividualField = (field, value) => {
    setEventDetailState(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const isCreator = () => {
    if (eventData && userData) {
      return eventData.created_by.id === userData.id;
    }
    return false;
  };

  const isCoHost = () => {
    if (!eventData || !eventData.co_hosts || !Array.isArray(eventData.co_hosts)) {
      return false;
    }

    for (const coHost of eventData.co_hosts) {
      if (userData.id === coHost.id) {
        return true;
      }
    }

    return false;
  };

  const isHost = () => {
    return isCreator() || isCoHost();
  }

  const reloadData = () => {
    setRefresh(!refresh); // Toggle to trigger re-render
  };

  const renderRSVPStatus = (eventData, userData, isCreator) => {
    // If the user is the creator, don't do anything.
    if (isCreator) {
      return null;
    }

    // Otherwise, check the invites to see the user's RSVP status.
    return (
      <div className="rsvp-container">
        {eventData.invites && eventData.invites.map((invite, index) => {
          if (invite.invitee.id === userData.id) {
            if (invite.rsvp_state === "YES") {
              return (
                <div key={index}>
                  <p className="rsvp-going">✅ You are going to this event.</p>
                </div>
              );
            } else {
              return (
                <div key={index}>
                  <p className="rsvp-not-going">❌ You are not going to this event.</p>
                </div>
              );
            }
          }
          return null;
        })}
      </div>
    );
  };

  const findEventInviteId = (userId) => {
    return eventData.invites.find(invite => invite.invitee.id === userId);
  }

  const handleAccept = async (e) => {
    let event_invite_id = findEventInviteId(userData.id).id;
    if (!event_invite_id) {
      toast.error('You are not invited to this event')
      return;
    }

    toast.promise(
      handleEventRSVP(event_invite_id, true),
      {
        loading: 'Sending RSVP...',
        success: <b>RSVP'd!</b>,
        error: <b>Oops Something went wrong</b>,
      })
    reloadData();
  }

  const handleReject = async (e) => {
    let event_invite_id = findEventInviteId(userData.id).id;
    if (!event_invite_id) {
      toast.error('You are not invited to this event')
      return;
    }

    toast.promise(
      handleEventRSVP(event_invite_id, false),
      {
        loading: 'Sending RSVP...',
        success: <b>Thanks for letting us know!</b>,
        error: <b>Oops Something went wrong</b>,
      })
    reloadData();
  }

  const handleCancel = async (e) => {
    setIsLoading(true);

    handleEventCancel(eventId)
      .then(response => {
        refreshEventData();
      });

    setTimeout(() => {
      setIsLoading(false); // Reset loading state
      navigate('/events');
    }, 500); // 0.5s delay
  }

  const handleDeleteDraft = async () => {
    setIsLoading(true);

    await handleEventDelete(eventId)

    setTimeout(() => {
      setIsLoading(false); // Reset loading state
      refreshEventData();
      navigate('/events');
    }, 500); // 0.5s delay
  };

  const handlePublishDraft = async () => {
    setIsLoading(true);

    handleEventPublish(eventId)
      .then(response => {
        refreshEventData();
      });

    setTimeout(() => {
      setIsLoading(false);
      navigate('/events');
    }, 500); // 0.5s delay
  };

  const updateEvent = () => {
    let payload = toEventData()
    debug.log('updating event details' + JSON.stringify(payload))
    debug.log('event id' + JSON.stringify(eventData.id))
    handleEventUpdate(eventData.id, payload).then((res) => {
      refreshEventData();
    })

    addParticipantsAndCoHosts();
    toast.success('Event updated successfully');
  };

  const toEventData = () => {
    return {
      title: eventDetailState.title,
      description: eventDetailState.description,
      additional_instructions: eventDetailState.additional_instructions,
      getting_there_instructions: eventDetailState.getting_there_instructions,
      venue_formatted_address: eventDetailState.venue_formatted_address,
      venue_str: eventDetailState.venue_str,
      start_time: eventDetailState.start_time,
      invites: eventDetailState.invites,
      co_hosts: eventDetailState.co_hosts,
    }
  }

  const addParticipantsAndCoHosts = () => {
    let invites = eventDetailState.invites;
    let cohosts = eventDetailState.co_hosts;


    let formattedInvites = invites.map(invite => ({
      first_name: invite.invitee.first_name,
      last_name: invite.invitee.last_name,
      phone_number: '+' + invite.invitee.username,
      participant_type: "invitee"
    }));

    let formattedCoHosts = cohosts.map(cohost => ({
      first_name: cohost.first_name,
      last_name: cohost.last_name,
      phone_number: '+' + cohost.username,
      participant_type: "co-host"
    }));

    // TODO: diff against the invites and cohost in the eventData
    let formattedParticipants = [...formattedInvites, ...formattedCoHosts];

    debug.log('All formatted participants:' + JSON.stringify(formattedParticipants));
    if (formattedParticipants.length === 0) {
      debug.log("No participants or co-hosts to add.");
      return;
    }

    let data = {
      event_id: eventData.id,
      participants: formattedParticipants
    };

    authAxios.post('/events/add_event_participants/', data)
      .then(response => {
        debug.log('Added participants and co-hosts', response.data);
      })
      .catch(error => {
        debug.log('Error:', error);
      });
  }

  function isEventStarted() {
    const currentTime = new Date();
    const eventEndTime = new Date(eventData.start_time);

    return currentTime > eventEndTime;
  }

  useEffect(() => {
    if (!authenticated) {
      debug.log('Not authenticated');
      navigate(`/login?redirectTo=${encodeURIComponent(window.location.pathname)}`);
    }
  }, [authenticated, navigate]);

  useEffect(() => {
    if (!authenticated()) {
      debug.log('Not authenticated');
      navigate(`/login?redirectTo=${encodeURIComponent(window.location.pathname)}`);
      return;
    }

    authAxios.get(`/events/${eventId}/`)
      .then(response => {
        setEventData(response.data.data); // Save the received data in the state
        let data = response.data.data;
        setEventDetailState({
          title: data.title,
          description: data.description,
          additional_instructions: data.additional_instructions,
          getting_there_instructions: data.getting_there_instructions,
          invites: data.invites || [],
          co_hosts: data.co_hosts || [],
          venue_formatted_address: data.venue_formatted_address,
          venue_str: data.venue_str,
          start_time: data.start_time,
        });
      })
      .catch(error => {
        console.log('Error:', error);
      });
  }, [authenticated, eventId, authAxios, refresh]);

  useEffect(() => {
    if (!userData || !userData.id) {
      fetchUserProfile();
      return;
    }
  }, [userData]);

  return (
    <div className="event-page-view-container">
      <Toaster toastOptions={{ duration: 2000 }} />
      <div className="event-page-rsvp-state">
        {eventData && userData && renderRSVPStatus(eventData, userData, isHost)}
      </div>
      <div className="event-page-detail">
        {eventData ? <EventDetail isHost={isHost()} eventData={eventData} eventDetailState={eventDetailState} setIndividualField={setIndividualField} /> : <LoadingScreen />}
      </div>
      <div className="action-btn-container">
        {!isHost() && eventData && eventData.event_state !== 'draft' && (
          <>
            <div onClick={handleAccept} className="action-btn accept">
              <FiThumbsUp />
              <small>Yes</small>
            </div>
            <div onClick={handleReject} className="action-btn reject">
              <FiThumbsDown />
              <small>No</small>
            </div>
          </>
        )}
        {eventData && eventData.event_state === 'draft' && isHost() && (
          <>
            <ConfirmableAction onConfirm={handleDeleteDraft} confirmMessage="Are you sure you want to delete?">
              <LoadableButton className="action-btn delete" loading={isLoading}>
                <>
                  <FiTrash2 />
                  <small>Delete</small>
                </>
              </LoadableButton>
            </ConfirmableAction>
            <ConfirmableAction onConfirm={handlePublishDraft} confirmMessage="Are you sure you want to publish?">
              <LoadableButton className="action-btn publish" loading={isLoading}>
                <>
                  <FiUpload />
                  <small>Publish</small>
                </>
              </LoadableButton>
            </ConfirmableAction>
          </>
        )}
        {eventData && !isEventStarted() && eventData.event_state === 'published' && isHost() && (
          <ConfirmableAction className="action-btn cancel" onConfirm={handleCancel} confirmMessage="Are you sure you want to cancel?">
            <LoadableButton className="action-btn cancel" loading={isLoading}>
              <>
                <FiX />
                <small>Cancel</small>
              </>
            </LoadableButton>
          </ConfirmableAction>
        )}
        {eventData && !isEventStarted() && isHost() && (
          <div onClick={updateEvent} className="action-btn save">
            <LoadableButton className="action-btn save" loading={isLoading}>
              <>
                <FiEdit3 />
                <small>Save</small>
              </>
            </LoadableButton>
          </div>
        )}
        {eventData && !isEventStarted() && (
          <div onClick={shareEvent} className="action-btn share">
            <LoadableButton className="action-btn share" loading={false}>
              <>
                <PiShareFatLight />
                <small>Share</small>
              </>
            </LoadableButton>
          </div>
        )}

        {isShareModalOpen &&
          <ShareLinkModal
            className="share-link-modal-element"
            isOpen={isShareModalOpen}
            closeModal={closeShareModal}
            url={"https://www.veqir.net/r/" + eventData.id}
            title={eventData?.title}
          />}
      </div>
    </div>
  );
}

export default EventPage;
