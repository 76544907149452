import React from 'react';
import FooterBar from './FooterBar';
import Navbar from './Navbar';

const callback = () => {
};

const FooterWrapper = ({ children }) => (
  <div className="home-container">
    <div className="card-container">
      <Navbar callback={callback} />
      <div className="card-container-body">
        {children}
      </div>
      <FooterBar />
    </div>
  </div>
);

export default FooterWrapper;