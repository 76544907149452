import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './css/eventcard.css'
import EventDetail from './EventDetail';
import { Toaster } from 'react-hot-toast'

const EventPreviewModal = ({ show, onHide, eventData, loading, handleCancel, handleConfirm, handleSaveDraft, submitting }) => {
  console.log('event preview modal' + JSON.stringify(eventData))
  return (
    <Modal show={show && loading} onHide={onHide} centered>
      <Toaster toastOptions={{
        position: 'bottom-center',
        duration: 2000,
      }} />
      <Modal.Header closeButton>
        <Modal.Title>Event Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EventDetail eventData={eventData} eventDetailState={eventData} isHost={false} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleSaveDraft}>
          Save as Draft
        </Button>
        <Button variant='light' onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant='primary' onClick={handleConfirm} disabled={submitting}>
          {submitting ? 'Publishing...' : 'Publish'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EventPreviewModal;