import { useContext } from 'react';
import { AxiosContext } from './AxiosContext';
import debug from '../debug_tools/debugLogger';
import { useUserContext } from './UserContext';
import { AuthContext } from './AuthContext';

const JeevesRequestUtils = () => {
  const { authAxios } = useContext(AxiosContext);
  const { userData, updateUserData } = useUserContext();
  const { authState, authenticated } = useContext(AuthContext);

  const handleEventPublish = async (event_id) => {
    return await authAxios.post('/events/publish_event/', { event_id: event_id });
  }

  const handleEventCancel = async (event_id) => {
    return authAxios.post('/events/cancel_event/', { event_id: event_id });
  }

  const handleEventDelete = async (event_id) => {
    try {
      return authAxios.delete(`/events/${event_id}/delete_event/`);
    } catch (e) {
      debug.log('error deleting event: ', JSON.stringify(e));
      throw e;
    }
  }

  const handleEventRSVP = async (event_id, is_rsvp, onEventUpdate = () => {}) => {
    try {
      if (is_rsvp) {
        return update_rsvp(event_id, 'YES');
      } else {
        return update_rsvp(event_id, 'NO');
      }
    } catch (e) {
      debug.log('error rsvp event: ', JSON.stringify(e))
      throw e;
    } finally {
      onEventUpdate();
    }
  }

  const update_rsvp = async (event_id, state) => {
    let data = {
      event_invite_id: event_id,
      rsvp_state: state,
    }
    return authAxios.post('/events/update_rsvp_state/', data);
  }

  const handleEventUpdate = async (event_id, payload, onEventUpdate = () => {}) => {
    try {
      console.log('updating event: ', JSON.stringify(event_id), payload);
      
      const response = authAxios.put(`/events/${event_id}/`, payload);
      onEventUpdate();
      return response;
    } catch (e) {
      debug.log('error deleting event: ', JSON.stringify(e));
      throw e;
    }
  }

  async function fetchUserProfile() {
    try {
      const usernum = `+${authState.phone_number}`;
      const response = await authAxios.post('/profile/get_user_profile/', { phone_number: usernum });

      if (response.status >= 200 && response.status < 300) {
        updateUserData(response.data.data);
      } else {
        console.error('Invalid response format', response);
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  }

  async function fetchData() {
    try {
      if (!authenticated()) {
        debug.log('not authenticated')
        return;
      }

      debug.group('auth', {
        userData: JSON.stringify(userData),
        authState: JSON.stringify(authState)
      })
      const phn = '+' + authState.phone_number;
      debug.log('user_phone:' + phn);
      return authAxios.post('/events/get_all_events_for_user/', { user: phn })
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return {
    handleEventPublish,
    handleEventCancel,
    handleEventDelete,
    handleEventRSVP,
    handleEventUpdate,
    fetchUserProfile,
    fetchData
  };
}

export default JeevesRequestUtils;